/** Project coded by TTS Devlopment freelancing. **
▄▄▄▄▄▄▄▄▄▄▄▄.▄▄ ·   ·▄▄▄▄  ▄▄▄ . ▌ ▐·
▀•██ ▀▀•██ ▀▐█ ▀.   ██· ██ ▀▄.▀·▪█·█▌
  ▐█.▪  ▐█.▪▄▀▀▀█▄  ▐█▪ ▐█▌▐▀▀▪▄▐█▐█•
  ▐█▌·  ▐█▌·▐█▄▪▐█  ██. ██ ▐█▄▄▌ ███ 
  ▀▀▀   ▀▀▀  ▀▀▀▀   ▀▀▀▀▀•  ▀▀▀ . ▀  
**************************************************/

/* ROOT */
/* BODY */
/* BODY LOADING */
/* HEADER */
/* SECTION */
/* TITLE CONTAINER */
/* VIRUS CONTAINER */
/* NAV BAR */
/* MODAL GRID */
/* MODAL GRID DATA */
/* MODAL GRID ARCHIVES */
/* MODAL NO GRID */
/* MODAL NO GRID GAME */
/* FOOTER */
/* MEDIA QUERIES */

/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script&family=Montserrat&display=swap");

/* ROOT */
:root {
  --white-color: #ffffff;
  --white-alpha-color: rgba(255, 255, 255, 0.5);
  --black-color: #000000;
  --black-alpha-color: rgba(0, 0, 0, 0.3);
  --red-color: #972020;
  --dark-red-color: #550000;
  --red-alpha-color: #97202080;
  --light-red-color: #d46a6a;
  --lighter-red-color: #ffaaaa;
  --grey-color: #6e6e6e;
  --dark-grey-color: #383838;
}

* {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

/* BODY */
body {
  min-height: 100vh;
  background-image: url(images/corona.gif);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* BODY LOADING */

body .loading {
  min-height: 100vh;
  background-color: var(--dark-grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

body .loading::before {
  content: "Loading...";
  color: white;
  font-size: 18px;
  margin-top: 100px;
}

body .loading::after {
  content: "";
  display: block;
  width: 300px;
  height: 300px;
  background-image: url(images/loading.gif);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: -1;
}

/* HEADER */
header .title {
  font-family: "Dancing Script", cursive;
  font-size: 25px;
  text-shadow: 3px 3px 5px var(--black-color);
  color: var(--white-color);
  text-align: left;
  padding: 5px;
}

/* SECTION */
section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* TITLE CONTAINER */
section .h2-container {
  flex-basis: 100%;
  margin-top: 10%;
  margin-bottom: 8%;
}

section h2 {
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  text-shadow: 3px 3px 5px var(--black-color);
  color: var(--white-color);
  text-align: center;
}

section h2 u {
  text-decoration: none;
  color: var(--red-color);
}

section h2 u:hover {
  text-shadow: var(--black-color) 0px 0px 20px;
  color: var(--white-color);
  cursor: pointer;
}

/* VIRUS CONTAINER */
section .virus-container {
  display: grid;
  grid-template-columns: 50vw 50vw;
  place-items: center;
}

section .virus1-text {
  color: var(--white-color);
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  text-shadow: 1px 1px 5px var(--black-color);
  grid-row: 2;
  grid-column: 1;
}

section .virus1 {
  grid-row: 1;
  grid-column: 1;
  border-radius: 50%;
}

section .virus1:hover {
  background-color: var(--white-alpha-color);
  box-shadow: var(--white-color) 0px 0px 20px;
}

section .virus2-text {
  color: var(--white-color);
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  text-shadow: 1px 1px 5px var(--black-color);
  grid-row: 2;
  grid-column: 2;
}

section .virus2 {
  grid-row: 1;
  grid-column: 2;
  border-radius: 50%;
}

section .virus2:hover {
  background-color: var(--white-alpha-color);
  box-shadow: var(--white-color) 0px 0px 20px;
}

/* NAV BAR */
section nav {
  flex-basis: 100%;
}

nav {
  display: flex;
  justify-content: flex-end;
}

nav .nav-item {
  height: 24px;
  width: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-right: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: var(--black-color) 2px 2px 5px;
  background-color: var(--white-color);
}

nav .nav-item.archives {
  background-image: url(images/archives.png);
}

nav .nav-item.contact {
  background-image: url(images/contact.png);
}

nav .nav-item.about {
  background-image: url(images/about.png);
}

nav div:hover {
  background-color: var(--grey-color);
  height: 25px;
  width: 21px;
  box-shadow: var(--white-color) 0px 0px 8px;
}

/* MODAL GRID */
.modal {
  position: fixed;
  z-index: 1;
  left: 10%;
  top: 140px;
  width: 80%;
  height: 80%;
  overflow: auto;
  background-color: var(--dark-red-color);
  border: var(--lighter-red-color) 5px solid;
  border-radius: 10px;
  color: var(--white-color);
}

.modal-info {
  position: absolute;
  top: 15px;
  left: 45px;
  color: var(--lighter-red-color);
  font-size: 12px;
}

.modal-url {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--lighter-red-color);
  font-size: 10px;
}

.modal .modal-title {
  padding: 5px;
  text-shadow: 3px 3px 5px var(--black-color);
  color: var(--lighter-red-color);
  padding-left: 15vw;
}

.modal .close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 5px;
  border-radius: 2px;
}

.modal .close-btn:hover {
  box-shadow: var(--lighter-red-color) 0px 0px 8px;
  width: 32px;
  height: 32px;
}

.modal .dl-btn {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 5px;
  border-radius: 2px;
}

.modal .dl-btn:hover {
  box-shadow: var(--lighter-red-color) 0px 0px 8px;
  width: 32px;
  height: 32px;
}

/* MODAL GRID DATA */
.modal .modal-content {
  display: grid;
  grid-template-columns: 20vw 60vw;
  place-items: center;
  color: var(--lighter-red-color);
}

.modal-filter-content {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
}

.modal-filter-content div {
  padding: 10px;
}

.select {
  width: 200px;
  padding: 2px;
  box-shadow: var(--lighter-red-color) 2px 2px 5px;
  border-radius: 5px;
  background-color: var(--lighter-red-color);
  color: var(--dark-red-color);
}

.select:hover {
  background-color: var(--dark-red-color);
  color: var(--lighter-red-color);
}

.modal-btn {
  box-shadow: var(--lighter-red-color) 1px 1px 3px;
  border-radius: 5px;
  background-color: var(--lighter-red-color);
  color: var(--dark-red-color);
  border-color: var(--lighter-red-color);
  padding: 5px;
  margin-top: 10px;
}

.modal-btn:hover {
  background-color: var(--dark-red-color);
  color: var(--lighter-red-color);
}

.modal-graph-content {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  height: 100%;
  padding-top: 20px;
}

.modal-graph-content-top {
  min-height: 200px;
  max-height: 300px;
  margin-top: 0px;
}

.modal-graph-content-bottom {
  min-height: 200px;
  max-height: 300px;
}

/* MODAL GRID ARCHIVES */
.modal .modal-content-archives {
  display: grid;
  grid-template-columns: 40vw 40vw;
  place-items: center;
  color: var(--lighter-red-color);
}

.modal-reports-content {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  text-align: center;
}

.modal-reports-content div {
  padding: 10px;
}

.archives-border {
  border: var(--lighter-red-color) solid 1px;
  border-radius: 5px;
  margin: 20px;
}

.archives-border hr {
  border-color: var(--lighter-red-color);
  margin-top: 20px;
}

.modal-results-content {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  height: 100%;
  text-align: center;
}

.modal-results-content div {
  padding: 10px;
}

.modal h3 {
  font-size: 26px;
  padding-top: 15px;
  margin-left: 2px;
}

/* MODAL NO GRID */
.modal .modal-title-center {
  padding: 5px;
  text-shadow: 3px 3px 5px var(--black-color);
  color: var(--lighter-red-color);
}

.modal-content-nogrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.modal-content-nogrid section {
  padding-top: 20px;
  color: var(--lighter-red-color);
  width: 60%;
}

.modal-content-nogrid section h3 {
  font-size: 26px;
  padding-top: 15px;
  margin-left: 2px;
}

.modal-content-nogrid section p {
  background-color: var(--lighter-red-color);
  border-radius: 5px;
  color: var(--dark-red-color);
  padding: 5px;
  margin: 5px;
  text-align: justify;
}

.modal-content-nogrid section a {
  text-decoration: none;
  color: var(--dark-red-color);
  font-style: italic;
  font-weight: bold;
}

.modal-content-nogrid form {
  padding-top: 20px;
  width: 60%;
}

.modal-content-nogrid .inputBox input {
  width: 100%;
  margin-bottom: 30px;
  padding: 5px;
  background-color: var(--lighter-red-color);
  color: var(--dark-red-color);
  border: none;
  outline: none;
  border-radius: 5px;
}

.modal-content-nogrid Label {
  color: var(--lighter-red-color);
}

.modal-content-nogrid .messageBox textarea {
  width: 100%;
  min-height: 200px;
  padding: 5px;
  background-color: var(--lighter-red-color);
  color: var(--dark-red-color);
  border: none;
  outline: none;
  border-radius: 5px;
}

.modal-content-nogrid .sendBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-content-nogrid .recaptcha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

/* MODAL NO GRID GAME */
.modal .modal-game-actions {
  position: relative;
  height: 35px;
}

.modal .modal-game-top {
  position: relative;
  height: 25vh;
  display: flex;
  align-items: center;
}

.modal .modal-game-message {
  position: relative;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-message {
  font-size: 20px;
  color: var(--lighter-red-color);
}

.modal-game-top h1 {
  font-size: 40px;
  text-align: center;
  position: absolute;
  width: 100%;
  padding-bottom: 80px;
  color: var(--lighter-red-color);
}

.game-count {
  display: inline-block;
  background: var(--lighter-red-color);
  border: var(--dark-red-color) dashed 17px;
  border-radius: 5px;
  color: var(--dark-red-color);
  font-size: 40px;
  padding: 10px 20px;
  text-align: center;
  margin: 0 auto;
  align-self: flex-end;
}

.modal .modal-game-main {
  height: 40vh;
  color: var(--lighter-red-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.modal-game-main .modal-game-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.game-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: var(--lighter-red-color) solid 1px;
  border-radius: 5px;
  width: 400px;
  padding: 10px;
  color: var(--dark-red-color);
}

.winMessage {
  color: var(--lighter-red-color);
  font-size: 20px;
  padding: 20px;
  margin: 10px 0px;
}

.game-instruction {
  margin: 5px;
  text-align: center;
  padding-bottom: 10px;
  color: var(--lighter-red-color);
}

.game-guess {
  background: var(--lighter-red-color);
  color: var(--dark-red-color);
  border: var(--dark-red-color) dashed 15px;
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  font-size: 30px;
  padding: 10px;
  width: 150px;
  text-align: center;
  display: block;
  margin-bottom: 5px;
}

.modal-game-main .modal-game-right {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.game-hint {
  font-size: 15px;
  border: var(--lighter-red-color) solid 1px;
  border-radius: 5px;
  width: 400px;
  padding: 10px;
}

.game-hint p {
  padding: 1px;
}

.game-hint p span {
  text-decoration: underline;
}

.game-hint .new-hint-btn {
  text-align: center;
}

.game-hint-title {
  text-align: center;
  font-style: normal;
  border-bottom: var(--lighter-red-color) solid 1px;
  margin-bottom: 10px;
}

/* FOOTER */
footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 0;
}

.footer-container p {
  color: var(--white-color);
  font-size: 12px;
  margin: 0 1vw;
  font-weight: normal;
  text-align: right;
  position: fixed;
  bottom: 0;
  right: 0;
}

/* MEDIA QUERIES */
/* 1200px */
@media (max-width: 1200px) {
  .modal-info {
    display: none;
  }
  .modal-url {
    display: none;
  }
  .modal .modal-game-main {
    flex-direction: column;
  }
}

/* MEDIA QUERIES */
/* 1000px */
@media (max-width: 1000px) {
  .modal .modal-content {
    display: flex;
    flex-wrap: wrap;
  }

  .modal .modal-content-archives {
    display: flex;
    flex-wrap: wrap;
  }

  .modal-game-main .modal-game-left {
    width: 95%;
  }

  .modal-game-main .modal-game-right {
    width: 95%;
  }

  .modal-content-nogrid section {
    width: 80%;
  }

  .modal-content-nogrid form {
    width: 80%;
  }
}

/* MEDIA QUERIES */
/* 650 */
@media (max-width: 650px) {
  .modal {
    width: 90%;
    left: 5%;
    top: 105px;
  }

  .modal .modal-title {
    padding-left: 0;
  }

  .modal-graph-content-top {
    padding-bottom: 20px;
  }

  header .title {
    font-size: 20px;
  }

  section .h2-container {
    margin-top: 40%;
    margin-bottom: 8%;
  }

  section h2 {
    font-size: 40px;
  }

  .modal-game-top h1 {
    font-size: 20px;
  }

  .game-check {
    margin-top: 100px;
  }

  .game-hint {
    margin-bottom: 10px;
  }

  .modal-content-nogrid section {
    width: 90%;
  }
}
